
<template>

  <div>
    <div class="contents">
      <div class="uk-background-fixed uk-background-center-center parallax-bg" uk-scrollspy="cls:  uk-animation-slide-bottom-small; target: .main-card ,.sub-card; delay: 300; repeat: true">
        <div class="main-card"  >
          <Breadcrumb class="uk-margin-left" />
          <div class="sub-card-top"  >
            <page-title></page-title>
            <div class="parent " >
              <div >
                <p class="text" style="font-size: 1.4rem;">
                  【プレミアムカー】中古車が高値になった理由とは？
                </p>
              </div>
              <div class="button-area">
                 <a href="https://www.worldingsysdom.com/stock_info/list/car" class="btn"><span>＞＞＞在庫一覧はこちら</span></a>
              </div>
            </div>
          </div>
          <div class="sub-card"  >
            プレミアムカーの中古車の販売価格は一時高騰していましたが、現在はようやく落ち着きをみせています。高値になった理由と価格高騰が落ち着いた要因を知ることで、購入するタイミングを理解できます。
            <div class="section-title">
              <span >中古車が高値になった理由と価格が落ち着いてきた要因とは？</span>
            </div>
            <div class="section-main " >
              <div class="uk-flex-top" uk-grid>
                  <div class="uk-width-2-3@m">
                    <p class="u-m0 u-pb1em">
                      数年来、中古車市場は高止まりの状況が続いていたため、稀に見る「バブル期」とも呼ばれていました。中古車が高値になった理由を理解することで、適正な価格で購入できる可能性が高まります。
                    </p>
                    <h3 class="heading-number" data-number="◆">中古車が高値になった4つの理由</h3>
                    <p class="u-m0 u-pb1em">
                      中古車の販売価格が高騰し続けていた理由は、主に以下の4点です。
                    </p>
                    <h4 style="color: #fff;"><b style="color: #c49a00;">■ </b>コロナ禍による需要増による値上げ</h4>
                    <p class="u-m0 u-pb1em">
                      もともと高騰傾向でしたが、コロナウイルスの感染拡大が追い打ちをかけてしまいました。人々が感染を予防するために公共交通機関の利用を減らし、代わりに自家用車を使う方が急増したのです。そのため、新車よりもリーズナブルな価格で購入できる中古車への需要が高まりました。
                    </p>
                    <h4 style="color: #fff;"><b style="color: #c49a00;">■ </b>生産減による値上げ</h4>
                    <p class="u-m0 u-pb1em">
                      自動車の生産数減少も、中古車価格を大幅に押し上げた要因です。自動車の需要が高まる一方で、新型コロナウイルスの感染拡大によるロックダウンで自動車や部品の製造工場が停止しました。
                    </p>
                    <p class="u-m0 u-pb1em">
                      その結果として新車の製造が大幅に遅れたため、新車よりも納期が早い中古車の需要が伸び、中古車価格が高騰したのです。
                    </p>
                  </div>
                  <div class="uk-width-1-3@m uk-flex-last">
                      <img src="@/assets/img/column-maker-mt/used-car-line-up-state.jpg" alt="Image">
                  </div>
              </div>
              <h4 style="color: #fff;"><b style="color: #c49a00;">■ </b>半導体不足</h4>
              <p class="u-m0 u-pb1em">
                新型コロナウイルスの感染拡大により、自動車だけではなくテレワークに使用するパソコンや巣ごもりによる家電製品の需要が伸びたため、様々な分野で半導体が必要となりました。
              </p>
              <p class="u-m0 u-pb1em">
                半導体の生産が追いつかなくなったことが影響し、新車の製造も遅くなっています。新車の納車時期が大幅に遅れているため、すぐに手に入る中古車の需要が伸びています。
              </p>
              <h4 style="color: #fff;"><b style="color: #c49a00;">■ </b>海外メーカーも軒並み値上げ</h4>
              <p class="u-m0 u-pb1em">
                海外の自動車メーカーも生産コストの上昇や供給不足を理由に車両価格を引き上げました。これが中古車市場にも大きな影響を及ぼしたのです。
              </p>
              <h3 class="heading-number" data-number="◆">中古車の販売価格が落ち着いてきた2つの要因</h3>
              <p class="u-m0 u-pb1em">
                コロナ禍をきっかけに中古車の販売価格の高止まり状態が続いていましたが、徐々に落ち着きを取り戻しています。価格が落ち着いた2つの要因をご紹介いたします。
              </p>
              <h4 style="color: #fff;"><b style="color: #c49a00;">■ </b>コロナ禍の解消</h4>
              <p class="u-m0 u-pb1em">
                新型コロナウイルスの感染拡大が収束に向かうにつれ、人々の生活様式も流行前のものに戻りつつあります。公共交通機関を利用される方も増えつつあり、ピーク時に比べて中古車への過度な需要も落ち着いてきたといえるでしょう。
              </p>
              <h4 style="color: #fff;"><b style="color: #c49a00;">■ </b>新車の納期改善</h4>
              <p class="u-m0 u-pb1em">
                コロナウイルスの流行も落ち着き、半導体不足の問題が徐々に解消されてきました。これにより、新車の生産や納期の遅れが改善され、新車の需要が徐々に回復しつつあります。結果として中古車への需要も減少し、販売価格は落ち着きを見せてきています。
              </p>
              <p class="u-m0 u-pb1em">
                ただし、一部の希少な旧車や人気モデルなどは投資目的で購入する方が増えているため、今後さらに高騰すると懸念されています。
              </p>
              <p class="u-m0 u-mt20 u-pb1em">
                希少モデルやプレミアムカーなどの購入を検討している方は、ぜひcar shop MIXまでお気軽にご相談ください。低走行の車種や25年規制が解除されるような車など、幅広い車種を取り揃えています。予算やニーズに合わせて、最適な一台を探してみてはいかがでしょうか。
              </p>
              <div class="c-btn">
                <router-link to="/jigyo/oldCars" class="c-btn-anchor">サービスについて</router-link>
              </div>
            </div>
          </div>
          <div class="sub-card"  >
            <div class="section-title">
              <span >希少価値の高い中古のプレミアムカーを販売！</span>
            </div>
            <div class="section-main " >
              <div class="uk-flex-top" uk-grid>
                  <div class="uk-width-2-3@m">
                    <p class="u-m0 u-pb1em">
                      中古車市場は、コロナウイルスの流行や新車の生産数減少、半導体不足、海外メーカーの値上げなどを理由に大幅に高騰していました。しかし、コロナ禍の解消や新車納期の改善によって、少しずつ販売価格は落ち着きつつあります。
                      </p>
                      <p class="u-m0 u-pb1em">
                        ただし、希少価値の高いモデルや人気車種の中古車については、今後さらなる高騰が懸念されています。中古車の購入を検討されている場合は、なるべく早めに準備を進めることが大切です。
                    </p>
                    <p class="u-m0 u-pb1em">
                      中古車のプレミアムカーの購入をご検討中でしたら、car shop MIXにお任せください。一般的に市場に出回らないようなプレミアム車種をご用意しています。
                      </p>
                      <p class="u-m0 u-pb1em">
                        公式サイトで中古車の在庫をチェックすることも可能です。万が一希望する車種の在庫がない場合は、オークションサイトなどを活用しながら、お客様の代わりにご希望の一台をお探しいたします。
                      </p>
                      <p class="u-m0 u-pb1em">
                        また、自動車板金・塗装・カークリーニング・オイル交換などの車両メンテナンスもお任せいただけます。中古車に関する疑問やお困りごとがございましたら、ぜひお気軽にご相談ください。
                    </p>
                  </div>
                  <div class="uk-width-1-3@m uk-flex-last">
                    <img alt="白色の日産スカイライン GT-R33" src="@/assets/img/column-maker-mt/white-nissan-skyline-gt-r.jpg">
                  </div>
              </div>
              <div class="c-btn">
                <a class="c-btn-anchor" href="https://www.worldingsysdom.com/stock_info/list/car" >在庫一覧</a>
              </div>
            </div>
          </div>
          <column-footer :title="'希少モデルやプレミアムカーの購入を検討している方は中古車販売のcar shop MIXへ'" />
        </div>
      </div>
    </div>
  </div>
  
</template>


<script>
import Breadcrumb from "../../../modules/parts/Breadcrumb.vue";
import ColumnFooter from "./Footer.vue";
export default {
  components: {
    Breadcrumb,
    ColumnFooter
  },
}
</script>



<style scoped lang="scss">
.sub-card-top{
  background-image:url(~@/assets/img/column-maker-mt/head-mv2-03.jpg);
}
.parallax-bg {
  background-image:url(~@/assets/img/bg-image3.jpg);
  
}
.access-list{
  *{
    max-height: 100%;
    max-width: 100%;
  }
  iframe{
    width: 60vw;
    height: 50vh;
  }
}

</style>
